.card {
    border : none;
}

.card-title h6 {
    color: $grey-darken-1;
    margin: 0px;
    text-overflow: ellipsis;
}

.card h4 {
    margin-bottom: 24px;
}

@media (max-width: 992px) {
    .card {
        margin-bottom: 20px;
    }
}