//tables

table.table {
    margin-bottom: 0px;

    a {
        color:#005CB9;
        word-break: break-all;
    }

    thead th {
        font-weight: bold;
        font-size: 1rem;
        padding-top: 0.5rem !important;
    }

    td {
        font-size: 1rem;

        &.no-results {
            background-color: $grey-lighten-4;

            &:hover {
                background-color: $white-base;
            }
        }

        .form-check {
            line-height: 1;
        }

        .form-check-input[type=checkbox] + label:before, .form-check-input[type=checkbox]:not(.filled-in) + label:after, label.btn input[type=checkbox] + label:before, label.btn input[type=checkbox]:not(.filled-in) + label:after {
            margin: 0px;
            top: -7px;
        }

        .form-check-input[type=checkbox]:checked + label:before, label.btn input[type=checkbox]:checked + label:before {
            top: -12px;
        }
    }

    .table-clickable {
        tr:hover td {
            background-color: $grey-lighten-4;
            cursor: pointer;
        }
    }
}

.table-clickable table {
  tr:hover td,
  tr:focus td,
  tr:active td {
    background-color: $grey-lighten-3;
    cursor: pointer;
  }
}

//pagination
table.table tfoot .mdb-pagination {
  padding: 1.1rem 0px 0px 0px;

  &:hover {
    background-color: transparent;
    cursor: pointer;
  }

  ul.pagination {
    margin: 0px;
  }
}


@media (max-width: 992px) {
  table.table,
  table {
    margin-bottom: 0px;

    a {
      word-break: normal;
    }
  }
}

table.table-c {
  margin-bottom: 0px;

  a {
      word-break: break-all;
  }

  thead th {
      font-weight: bold;
      font-size: 1rem;
      padding-top: 0.5rem !important;
  }

  td {
      font-size: 1rem;
      padding-top: 10px; 
      &.no-results {
          background-color: $grey-lighten-4;

          &:hover {
              background-color: $white-base;
          }
      }

      .form-check {
          line-height: 1;
      }

      .form-check-input[type=checkbox] + label:before, .form-check-input[type=checkbox]:not(.filled-in) + label:after, label.btn input[type=checkbox] + label:before, label.btn input[type=checkbox]:not(.filled-in) + label:after {
          margin: 0px;
          top: 3px;
      }

      .form-check-input[type=checkbox]:checked + label:before, label.btn input[type=checkbox]:checked + label:before {
          top: -2px;
      }

      // .md-form mdb-select {
      //   padding-bottom: 0px !important;
      //   padding-left: 0px !important;
      //   background-color: transparent !important;
      // }

      // .md-form .form-control {
      //   padding-top: 0px !important;
      // }
  }

  .table-clickable {
      tr:hover td {
          background-color: $grey-lighten-4;
          cursor: pointer;
      }
  }
}