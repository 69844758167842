
@media (min-width: 992px) {
  .mobile-show {
    display: none;    
  }
}

@media (max-width: 992px) {
  .desktop-show {
    display: none;
  }
}

.segal-spinner {
  position: absolute; 
  left: 50%; 
  color: $primary-blue !important;

  &.full-page {
    top: 50%;
  }
}

//Alerts
.alert {
  border: none;
  border-radius: 0px;
  padding: 1rem 1.25rem;

  span {
    color: #000 !important;
    margin-left: 10px;
  }

  &.alert-danger {
    color: $danger-1;
    background-color: #F8E6EA;
  }

  ul {
    margin: .3rem 0rem;
  }
}

//Toasts
.overlay-container #toast-container {
  > mdb-toast-component {
    width: 500px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1410px;
  }  
}

.tabs.segal-plan {
  margin: 0px;
  padding: 0px 0px 10px 0px;
  margin-left: auto;
  li {
    display: inline-block;

    a:only-child {
      padding: 0.6rem 1.0rem;
      color: $black-base;
      font-size: 1rem;      
    }

    &.active a {
      border-bottom: 3px solid map-get($primary, A200);
      color: map-get($primary, A200);
      font-weight: bold;
    }    
  }
}
