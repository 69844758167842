
.search-row {
    margin-top: 20px;
}

.mdb-select-value-label,
.mdb-select-placeholder
{
    padding-left: 10px !important;
}

.md-form.dashboard-search {
    .sort-by {
        height: calc(3.1rem) !important;
        //height: calc(-2.0rem);
        height: auto;
        padding: 12px 15px !important;
        border-radius: 0rem;
        border: none;
        border-bottom: 1px solid $grey-lighten-1;
        margin-left: 0px;
        width: 100%;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        background-color: $white-base !important;
    }

    .prefix {
        font-size: 1.25rem;
        left: 10px;
        top: 5px;
        color: $grey-base;
        z-index: 999;
        &.active i {
            color: #4285f4;
        }
        ~ input {
            margin-left: 0px;
            width: 100%;
            padding: 6px 40px !important;
            box-sizing: border-box;
            border: 1px solid #ced4da;
            border-radius: .25rem;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            background-color: $white-base !important;
            &::placeholder {
                color: $black-base;
            }
        }
    }
}

.md-control {
    background-color: $grey-lighten-2;
}

.md-form mdb-date-picker .datepicker-inline-icon {
  right: 20px !important;
}

.md-form mdb-date-picker:not(.no-label) .datepicker-inline-icon {
    top: 13px !important;
}

.md-form mdb-date-picker {
    &:after {
        right: 15px;
    }
    + label {
        transform: translateY(0px) scale(0.8);
        margin-top: 3px;
        padding-left: 24px !important;       
    }
    + .danger {
        color: red !important;
    }
    &:read-only {
        border-bottom: 1px solid $grey-lighten-2 !important;
    }
}

.md-form input[type='text'],
.md-form input[type='password'],
.md-form input[type='email'],
.md-form input[type='url'],
.md-form input[type='time'],
.md-form input[type='tel'],
.md-form input[type='number'],
.md-form input[type='search-md'],
.md-form input[type='search'],
.md-form mdb-select,
.md-form mdb-select-2,
input.mdb-autocomplete,
.md-form textarea.md-textarea,
select :not(.picker__select--month):not(.picker__select--year),
.dropdown-btn
{
    background-color: $grey-lighten-2 !important;
    border-bottom: 1px solid $grey-lighten-1;
    padding-left: 20px !important;
    color: $black-base !important;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
    width: 100%;
    box-sizing: border-box; /* add this */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    margin-bottom: 0px !important;

    &:after {
        right: 15px;
    }
    + label {
        transform: translateY(0px) scale(0.8);
        margin-top: 3px;
        padding-left: 24px !important;
    }
    &:read-only {
        border-bottom: 1px solid $grey-lighten-2 !important;
    }
}

[type='date']::-webkit-inner-spin-button {
    display: none;
}
[type='date']::-webkit-calendar-picker-indicator {
    opacity: 0;
    width: 18px;
}

.md-form {
    margin-bottom: 1.7rem;
    .form-check {
        padding-left: 0px;
        line-height: 2;
        label,
        label.active {
            font-size: 1rem;
            padding-left: 30px !important;
            margin: 0px;
        }
    }
    .error-message,
    .success-message {
        position: static;
    }

    &.no-label {
        .form-control {
            padding: 0.5rem 0;
        }
    }
    margin-top: 0px;
    .form-control {
        padding: 1.8rem 0 0.5rem 0;
    }

    textarea.md-textarea.form-control {
        padding: 2rem;
        min-height: 245px;
        &.auto-height {
            height: auto;
            min-height: 0px;
        }
    }

    mdb-select {
        padding-bottom: 12px !important;
        padding-left: 24px !important;
        padding-right: 0px !important;
        border: none;
        height: calc(3em + 0.75rem + 2px) !important;
        border-bottom: 1px solid $grey-lighten-2 !important;
        border-radius: 0px !important;
        + label {
            transform: translateY(0px) scale(0.8);
            margin-top: 15px !important;
            padding-left: 24px !important;            
        }
        &.form-control-mdb:focus {
            border-bottom: 1px solid $primary-1 !important;
            + label {
                color: $primary-1 !important;
            }
        }
        > div > div.single {
            border-bottom: none !important;
        }
    }

    select:not(.picker__select--month):not(.picker__select--year) {
        padding-bottom: 12px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        padding-top: 2.0rem !important;
        border: none;
        border-bottom: 1px solid $grey-lighten-1 !important;
        border-radius: 0px !important;
        + label {
            transform: translateY(0px) scale(0.8);
            margin-top: 3px;
            padding-left: 34px !important;
        }
        &.form-control:focus {
            border-bottom: 1px solid $primary-1 !important;
            + label {
                color: $primary-1 !important;
            }
        }
    }

    label {
        margin-top: 9px;
        &.active {
            transform: translateY(0px) scale(0.8);
            padding-left: 24px !important;
            margin-top: 3px;
        }
    }
}

.md-form > input[type='date']:not(.browser-default) + label {
    margin-top: 9px;
    transform: translateY(0px) scale(0.8);
    transform-origin: 0 0;
    &.active {
        padding-left: 20px !important;
        margin-top: 9px;
    }
}

.md-form > input[type='time']:not(.browser-default) + label {
    margin-top: 9px;
    transform: translateY(0px) scale(0.8);
    transform-origin: 0 0;
    &.active {
        padding-left: 20px !important;
        margin-top: 9px;
    }
}

// .placeholder,
.md-control label,
.md-form label {
    width: 100%;
    padding-left: 20px !important;
    color: $black-base !important;
}

.placeholder {
    color: $grey-darken-2 !important;
}

mdb-select * {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
}

//autocomplete styling
.md-form {
    .ng-autocomplete {
        width: auto;
        height: 61px;
        label {
            position: absolute;
            transform: translateY(0px) scale(0.8);
            margin-top: 3px !important;
            padding-left: 24px !important;
            z-index: 999;
        }
        .autocomplete-container {
            .input-container {
                input {
                    height: 61px;
                    padding-top: 20px;
                    font-size: 1rem;
                }
                .x {
                    font-size: 20px;
                    margin-top: 10px;
                }
            }
        }
    }
}

//validation styling
.not-found {
    color: $danger-2;
    font-size: 0.8rem;
}
.prefix {
    font-size: 1.25rem;
    left: 10px;
    top: 7px;
    color: $grey-base;

    &.active i {
        color: #4285f4;
    }

    ~ input {
        margin-left: 0px;
        width: 100%;
        padding: 12px 55px;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;

        &::placeholder {
            color: $black-base;
        }
    }
}

//validation
.md-form {
    .form-control.form-invalid {
        border-bottom: 2px solid $danger-1 !important;
    }
    .form-control-mdb.form-invalid {
        border-bottom: 2px solid $danger-1 !important;
    }
    mdb-date-picker.form-invalid .mydp-date {
      border-bottom: 2px solid $danger-1 !important;
    }
    table.form-invalid thead th  {
        border-bottom: 2px solid $danger-1 !important;
    }
    h3.form-invalid {
        border-bottom: 2px solid $danger-1 !important;
    }
    mdb-select-2.form-invalid {
        border-bottom: 2px solid $danger-1 !important;
    }
}

.form-invalid {
    .angular-editor-textarea {
        border-bottom: 2px solid $danger-1 !important;
    }
}

button.mdb-autocomplete-clear {
    top: 35% !important;
    right: 10px !important;
}

.table-control mdb-select-2 {
    background-color:transparent !important; // works
    height: calc(1em + 0.75rem + 2px) !important; //works

    .form-control {
        padding-top: 8px !important; //works
        padding-left:0px !important ;
    }
    
}


