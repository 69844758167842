.modal-footer.left-align {
    justify-content: flex-start;
  }
  
.modal-open .modal {
    overflow-x: hidden !important;
    overflow-y: auto !important;
}

.modal-header {
    background-color: #001C71;
    color: $white-base;
    padding: 1.1rem 1.8rem;
    &.alt {
        color: $black-base;
        background-color: $white-base;
        border: none;
        padding: 1.1rem 2.75rem 0rem 2.75rem;
    }
}

.modal-content:not(.card-image) .modal-header .close {
    right: 25px;
    color: $white-base;
    font-size: 1.8rem;
}

.modal-body {
    padding: 1.8rem;
}

.modal-footer {
    padding: 1rem 1.8rem;
    justify-content: center;
    .btn {
        margin-right: 1rem;
        &:last-child {
            margin-right: 0px;
        }
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1400px;
    }
    .modal-lg {
        max-width: 1000px;
    }

}

@media (max-width: 992px) {
    .modal-body {
        padding: 1rem .5rem;
    }
    .modal-footer {
        flex-wrap: wrap;
        .btn,
        .btn:last-child,
        .btn[class*=btn-outline-].btn-sm,
        button.btn[class*=btn-outline-] {
            width: 100%;
            margin: .5rem !important;
            padding: .75rem;
        }
    }
}

.cdk-overlay-container { z-index: 1051 !important; }