$grey-lighten-4: #f5f5f5;
$grey-lighten-3: #eeeeee;
$grey-lighten-2: #e8e8e8;
$grey-lighten-1: #bdbdbd;

$grey-darken-2: #616161;
$grey-darken-1: #747576;

$white-base: #fff;
$black-base: #252729;
$grey-base: #9e9e9e;

$danger-1: #D81C00;
$danger-2: #A02816;

$primary-1:  #325dff;
$primary-blue: #001C71;

$primary-link: #005CB9;

$primary: ( 50 : #E5EAF0, 100 : #BECADC, 200 : #94A7C4, 300 : #6B86AD, 400 : #4A6C9D, 500 : #22548F, 600 : #0063A7, 700 : #0F437B, 800 : #06396E, 900 : #002957, A100 : #6585ff, A200 : #325dff, A400 : #0035fe, A700 : #002fe5, contrast: ( 50 : #000000, 100 : #000000, 200 : #000000, 300 : #ffffff, 400 : #ffffff, 500 : #ffffff, 600 : #ffffff, 700 : #ffffff, 800 : #ffffff, 900 : #ffffff, A100 : #000000, A200 : #ffffff, A400 : #ffffff, A700 : #ffffff, ) );
