

body {
    background-color: #f5f5f5;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  }
  
  .wrap {
    padding-top: 25px;
    padding-bottom: 5px;
  
    &.alt {
      background-color: $grey-lighten-4;
      border-top: 1px solid #C4C4C4;
      border-bottom: 1px solid #C4C4C4;
    }
  }

  a {
    color: $primary-link;
}
  
  h2 {
    display: inline-block;
    margin-bottom: 25px;
    width: 100%;
    font-weight: 500;
    font-size: 40px;
    color:#001C71;
    font-family: linotype-sabon, Palatino, Palatino Linotype, Palatino LT STD, Book Antiqua, Georgia, serif;
  }
  
  h3 {
    font-weight: 400;
    font-family: aktiv-grotesk, sans-serif !important;
    margin-bottom: 1.5rem;
    
    button.btn {
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: 3px;
    }
  }

  @media (max-width: 992px) {

    h2 {
      font-size: 40px;
      line-height: 50px;
    }
    
  }