.btn-segal {
    background-color: #001C71!important;
    color: white !important;
}

.btn-segal-green {
    background-color: #3DAE2B!important;
    color: white !important;
}

.btn-segal-orange {
    background-color: #E65300!important;
    color: white !important;
}

.btn,
a.btn,
button.btn,
button.btn[class*=btn-outline-] {
    font-size: .8rem;
    letter-spacing: 1.2px;
    padding: 0.6rem 1.1rem;
    box-shadow: none !important;
    line-height: 1.2;
    &.stretch {
        width: 100%;
    }
}

.btn[class*=btn-outline-].btn-lg {
    padding: .7rem 1rem !important;
    line-height: 2.5;
    margin: 0px;
    font-size: 0.9rem;
}

.icon-btn.btn {
    font-size: 1rem;
    margin: 0px;
    padding: 0.25rem 0.5rem;
}

@media (max-width: 992px) {
    .btn,
    a.btn,
    button.btn,
    button.btn[class*=btn-outline-] {
        &.stretch-lg {
            width: 100%;
            margin-left: 0px;
            margin-right: 0px;
            &.float-right,
            &.float-left {
                float: none !important;
            }
        }
    }
}