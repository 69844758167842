/* You can add global styles to this file, and also import other style files */

@import 'app/_styles/_colors';
@import 'app/_styles/_custom';
@import 'app/_styles/_buttons';
@import 'app/_styles/_nav';
@import 'app/_styles/_tables';
@import 'app/_styles/_modals';
@import 'app/_styles/_content';
@import 'app/_styles/_form-control';
@import 'app/_styles/_cards';
