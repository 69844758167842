
.nav-item {
  font-family: aktiv-grotesk, sans-serif !important;
  margin-right: 30px;
}
.navbar {
  background-color:#001C71 !important;
 }
 
a.logo > img {
    height: 26px; 
}

.navbar.navbar-light.segal-nav {
  padding: 12px;

  .active {
    a.nav-link {        
        font-weight: bold !important;
        background-color: transparent !important;        
    }
    }

    .active-sub {
        a.nav-link {        
            font-weight: bold !important;
            background-color: transparent !important;
            border-bottom: 3px solid #325dff;
            color: #325dff;
        }
        }

  .navbar-nav .nav-item a.nav-link {
    padding: 15px 25px;
    font-weight: 400;
    color: #002957;    
    white-space: nowrap;
  } 

}

.navbar.navbar-light.no-shadow {
    box-shadow: none !important;
}

@media (max-width: 992px) {
    .navbar-nav {
        margin-top: 15px;
    }
  }

